<template>
  <div>
    <!-- 关于我们banner组件 -->
    <about-banner></about-banner>
    <div class="about">
      <div class="about_title">
        <h3>公司简介</h3>
        <p>company profile</p>
      </div>
      <div class="desc">
        <p>
          合肥广为数盈信息科技有限公司(简称：广为科技)坐落于中国四大科教基地之一、国家级园林城市安徽省会合肥市，是一家立足于物联网应用，致力于企业产业链信息化、产品信息溯源应用领域的信息系统咨询、开发与应用推广，涵盖了企业产业链信息化与产品信息溯源咨询与设计、软件产品、标识设备及系统集成应用的研发、销售及相关产品服务为一体的高科技企业。
        </p>
        <p>
          物联网被视为互联网的应用拓展，应用创新是物联网发展的核心，以用户体验为核心是物联网发展的灵魂。公司一直秉着“以人为本、科技为导”的研发理念，不断加大物联网技术在企业产业链上应用的研发成本，从原材料、生产制造、仓储物流及多级经销商等整个企业产业链信息化及产品信息溯源上提供整套优质的产品、优越的解决方案。
        </p>
        <p>
          “以诚信至上，服务为先”的销售与服务理念，关心每一个客户，注重每一次服务，携手与客户共同发展、实现客户潜在价值。公司现已在种子、农化、日化、种子、酒类及药品等行业成功的进行了推广与应用，得到了广大客户的支持与认可并得到了良好的评价。我们相信，通过我们的不断努力和追求，一定能够实现与您的企业互利
          共赢，创造美好未来！
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import aboutBanner from "./components/about-banner.vue";
export default {
  components: { aboutBanner },
  name: "About",
};
</script>

<style lang="less" scoped>
.about {
  padding: 50px 30px;
  text-align: center;
  .about_title {
    h3 {
      color: #302874;
    }
    p {
      font-size: 25px;
      color: #4fbdf6;
      text-transform: uppercase;
      margin-top: 0;
    }
  }
  h3 {
    font-size: 50px;
    font-weight: 700;
  }

  .desc {
    p {
      text-align: justify;
      text-indent: 2em;
      font-size: 35px;
      color: #333;
      margin: 20px 0;
    }
  }
}
</style>