<template>
  <div class="about-banner">
    <div class="banner_info">
      <h3>广为科技</h3>
      <p>追溯数字化解决方案提供商</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.about-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 400px;
  background: url("~@/assets/img/banner/about_banner_1.jpg") no-repeat center /
    cover;
  &::after {
    content: "";
    position: absolute;
    inset: 0;
    background: rgba(25, 27, 73, 0.5);
  }
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background: url("~@/assets/img/product/product_banner_bg.png") repeat;
  }
}
.banner_info {
  margin-bottom: 40px;
  position: absolute;
  color: #fff;
  z-index: 20;
  text-align: center;
  h3 {
    font-size: 60px;
    font-weight: 700;
  }
  p {
    font-size: 45px;
  }
}
</style>